import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H3 } from "../typography/Headings"
import P from "../typography/P"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

const CardPrint = ({ image, title, text, date, link }) => {
  return (
    <div className="relative">
      <Link to={link}>
        <div className="relative text-center group">
          <div className="absolute inset-0 z-10 flex items-center justify-center w-full h-full duration-300 bg-black opacity-0 transistion group-hover:opacity-70">
            <ChevronDoubleRightIcon className="w-6 h-6 text-white" />
          </div>
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            className="relative w-full align-top"
          />
        </div>
      </Link>

      <div className="relative z-10 w-11/12 p-6 mx-auto -mt-6 bg-white">
        <Link
          to={link}
          className="transition-colors duration-300 hover:text-brand"
        >
          <H3 className="mb-3 font-display">{title}</H3>
        </Link>
        <P className="mb-3 text-sm">{text}</P>
        {date && (
          <div className="mb-3 font-display">
            <div className="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded">
              {date}
            </div>
          </div>
        )}

        <Link
          to={link}
          className="transition-colors duration-300 hover:text-brand"
        >
          Zur Publikation
          <ChevronDoubleRightIcon className="inline w-4 h-4 ml-2" />
        </Link>
      </div>
    </div>
  )
}

export default CardPrint
