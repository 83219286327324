import React from "react";

const GridTwo = ({ children, itemsCenter, itemsStart, className }) => {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 gap-8 ${
        itemsCenter ? "items-center" : ""
      } ${itemsStart ? "items-start" : ""} ${className ? className : ""}`}
    >
      {children}
    </div>
  );
};

export default GridTwo;
