import React from "react"
import { graphql } from "gatsby"
import Container from "../components/common/Container"
import GridTwo from "../components/common/GridTwo"
import GridThree from "../components/common/GridThree"
import CardPrint from "../components/common/CardPrint"
import Layout from "./../components/layouts/Default"
import { H2, H3 } from "../components/typography/Headings"
import P from "../components/typography/P"
import PageHeader from "../components/common/PageHeader"
import Breadcrumb from "../components/common/Breadcrumb"
import Button from "../components/common/Button"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Verlag" desc="">
      <PageHeader title="Verlag" image={data.header} />
      <Breadcrumb pages={[{ name: "Verlag", to: "/verlag/" }]} />

      <Container>
        <div className="mb-8 md:mb-16">
          <H2 className="mb-5 font-display">Verlag</H2>
          <GridTwo>
            <div className="space-y-5">
              <P>
                Bei jeder Begegnung mit der Natur kann man mehr finden, als man
                gesucht hat. Diese Erfahrung macht Peter Scherbuk als suchender
                Fotograf weltweit. Weil er dabei nicht nur beeindruckende
                Motive, sondern auch Menschen mit beeindruckendem Blick für
                sehenswerte Motive fand, gründete er den Verlag NATURBLICK. Das
                gleichnamige Magazin geriet erstmals im Oktober 2001 ans Licht
                der Öffentlichkeit.
              </P>
              <P>
                Seitdem präsentiert es viermal pro Jahr den außergewöhnlichen
                Blick. Den Blick von Fotografen, die von Natur aus alles andere
                als blind für die Natur sind. Und NATURBLICK erklärt, welche
                Technik und Tricks Fotografen zum Erfolg führen.
              </P>
            </div>
            <div className="space-y-5">
              <P>
                Für seine Stammleser ist die Zeitschrift NATURBLICK längst zum
                Sammlerobjekt geworden. Das Verlagsprogramm von NATURBLICK
                wächst stetig: Fotokalender führen mit optischen Highlights
                durch das Jahr, und seit 2004 ist NATURBLICK auch als
                Buchproduzent auf dem Markt vertreten.
              </P>
              <P>
                Für seine Stammleser ist die Zeitschrift NATURBLICK längst zum
                Sammlerobjekt geworden. Das Verlagsprogramm von NATURBLICK
                wächst stetig: Fotokalender führen mit optischen Highlights
                durch das Jahr, und seit 2004 ist NATURBLICK auch als
                Buchproduzent auf dem Markt vertreten.
              </P>
            </div>
          </GridTwo>
        </div>
      </Container>

      <Container className="space-y-8 md:space-y-16">
        <div>
          <div className="mb-8 md:mb-16">
            <H3 className="mb-5 font-display">Zeitschriften</H3>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Wir von NATURBLICK behaupten: Egoismus in Sachen Natur ist
                  gut. Unser Ziel ist es, diesen Egoismus in unseren Lesern zu
                  wecken. Wir verführen sie, die Schönheiten der Natur für sich
                  zu gewinnen. Auch solche, die sich im vermeintlich Hässlichen
                  verstecken. Denn wer mit fotografischem Blick auf
                  Schönheitssuche geht, lernt das Staunen und stellt fest: Die
                  Natur feiert eine wunderbare Premiere.
                </P>
                <P>Und das an jedem Tag!</P>
                <P>
                  Staunende Gäste dieser Premiere bringen es nicht fertig, der
                  Natur zu schaden. Sie helfen, die Natur zu bewahren. So bauen
                  sie Brücken für die Zukunft.{" "}
                </P>
                <P>
                  NATURBLICK versteht sich als Forum und wendet sich an
                  naturverbundene Profifotografen. Sie legen Wert darauf, ihre
                  Erfahrungen preiszugeben. So helfen sie begeisterten
                  Amateuren, auf Profiwegen erfolgreich zu werden.{" "}
                </P>
              </div>
              <div className="space-y-5">
                <P>
                  <strong>NATURBLICK bietet mehr:</strong>
                </P>
                <ul className="pl-3 space-y-3 list-disc">
                  <li className="list-item">
                    Wissenswertes zum technischen Equipment, Tipps in sämtlichen
                    Bereichen zwischen Makro- und Panoramafotografie
                  </li>
                  <li className="list-item">
                    Informationen über Veranstaltungen und Workshops
                  </li>
                  <li className="list-item">
                    Organisation von Fotoexkursionen und -workshops
                  </li>
                </ul>
                <P>
                  Sie können das NATURBLICK-Magazin auf verschiedene Weise
                  beziehen: im Abo oder als Geschenk-Abo. Einzelne Hefte können
                  Sie über den Bestellschein erhalten. NATURBLICK können Sie
                  auch im Zeitschriftenhandel – bundesweit an über 900
                  Verkaufsstellen und in Österreich und der Schweiz – erhalten.
                  Nachbestellungen von Einzelausgaben sind nur direkt beim
                  Verlag möglich.
                </P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.zeitschriftenFirst.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/zeitschriften/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
          <div className="my-8 text-center">
            <Button
              text="Alle Zeitschriften anzeigen"
              to="/verlag/zeitschriften/"
              className="mx-auto my-8"
            ></Button>
          </div>
        </div>
        <div>
          <div className="mb-8 md:mb-16">
            <H3 className="mb-5 font-display">Sonderausgaben</H3>
          </div>
          <GridThree>
            {data.sonderausgaben.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/sonderausgaben/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>
        <div>
          <div className="mb-8 md:mb-16">
            <H3 className="mb-5 font-display">NATURBLICK | Spezial 2020</H3>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Wollen Sie Wildtiere in ihrem natuürlichen Lebensraum
                  fotografieren, ohne sie zu beunruhigen oder in ihrem
                  ursprünglichen Verhalten zu stören?
                </P>
                <P>
                  Wollen Sie dabei keine Standardbilder machen, von denen es
                  schon zu viele gibt, sondern herausragende Tierbilder, die den
                  Betrachter in ihren Bann ziehen?
                </P>
                <P>Dann müssen Sie dieses NATURBLICK-Spezial lesen!</P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.spezial.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/spezial/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>
        <div>
          <div className="mb-8 md:mb-16">
            <H3 className="mb-5 font-display">NATURBLICK | Kalender</H3>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Kalender sind Blickfänger par excellence, nicht zuletzt durch
                  ihre ganzjährige Nutzung. Ohne Kalender läuft nichts: Timing,
                  Planung, Vermeiden von Stress. Sie stehen immer wieder im
                  Fokus der Aufmerksamkeit und sind allen digitalen Medien zum
                  Trotz kaum wegzudenken.
                </P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.kalender.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/kalender/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>
        <div>
          <div className="mb-8 md:mb-16">
            <H3 className="mb-5 font-display">Bücher</H3>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Der Herausgeber einer Londoner Zeitung startete eine Umfrage
                  zum Thema: „Bücher, die mir geholfen haben.“ Eine Antwort
                  lautete: „Das Kochbuch meiner Mutter und das Scheckbuch meines
                  Vaters.“
                </P>
                <P>
                  Ein Buch, das NATURBLICK herausbringt, hilft anders: Es ist
                  wie ein großzügiger Gastgeber in Natur und Wildnis. Es deckt
                  seinen Lesern den Tisch mit reichen Eindrücken. Es verführt
                  seine Leser, in der Wildnis zu hören, zu sehen und zu riechen,
                  als seien sie mittendrin. Und das auf jeder Seite!{" "}
                </P>
                <P>
                  Ein Gelehrter antwortete einst auf die Frage, warum man wohl
                  geliehene Bücher so selten zurückbekomme: „Weil es leichter
                  ist, die Bücher zu behalten, als das, was drin steht.“
                </P>
                <P>
                  Darauf können wir nur sagen: Dieser Gelehrte hat noch kein
                  Buch von NATURBLICK gelesen!{" "}
                </P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.buecher.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/buecher/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/verlag/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: { cropFocus: ATTENTION }, width: 1600)
      }
    }
    zeitschriftenFirst: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Zeitschriften" } }
      limit: 3
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: ATTENTION }
              width: 1024
            )
          }
        }
        title
        title_slug
      }
    }
    sonderausgaben: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Sonderausgaben" } }
      limit: 3
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: ATTENTION }
              width: 1024
            )
          }
        }
        title
        title_slug
      }
    }
    kalender: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Kalender" } }
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: ATTENTION }
              width: 1024
            )
          }
        }
        title
        title_slug
      }
    }
    buecher: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Bücher" } }
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: ATTENTION }
              width: 1024
            )
          }
        }
        title
        title_slug
      }
    }
    spezial: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Spezial" } }
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: ATTENTION }
              width: 1024
            )
          }
        }
        title
        title_slug
      }
    }
  }
`
